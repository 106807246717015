import { Link, graphql } from 'gatsby';
import React, { useState } from 'react';

import CollapsibleSection from '../components/shared/collapsable';
import Layout from '../components/layout';
import { Modal } from 'react-bootstrap';
import PackageCta from '../components/home/package-cta';
// import '../styles/home.css';
import SEO from '../components/seo';
import ScrollAnimation from 'react-animate-on-scroll';
import arrow from '../images/gpon/arrow.svg';
import cardsBg from '../images/gpon/cards-bg.svg';
import faqBottom from '../images/gpon/faq-bottom.svg';
import faqTop from '../images/gpon/faq-top.svg';
import heroBg from '../images/gpon/hero.png';
import heroPlay from '../images/gpon/heroPlay.svg';
import hiwBg from '../images/gpon/hiw-bg.svg';
import hiwBottomMobile from '../images/gpon/hiw-bg-mobile-bottom.svg';
import hiwImg from '../images/gpon/hiw-img.svg';
import meta from '../constants/meta-descriptions';
import reason1 from '../images/gpon/reason-1.svg';
import reason2 from '../images/gpon/reason-2.svg';
import reason3 from '../images/gpon/reason-3.svg';
import reason4 from '../images/gpon/reason-4.svg';
import reason5 from '../images/gpon/reason-5.svg';
import securityHigh from '../images/gpon/security-high.svg';
import securityLow from '../images/gpon/security-low.svg';
import securityMedium from '../images/gpon/security-medium.svg';
import signalAdsl from '../images/gpon/signal-adsl.svg';
import signalDocsis from '../images/gpon/signal-docsis.svg';
import signalGpon from '../images/gpon/signal-gpon.svg';
import signalGprs from '../images/gpon/signal-gprs.svg';
import signalLan from '../images/gpon/signal-lan.svg';
import signalWireless from '../images/gpon/signal-wireless.svg';
import styled from 'styled-components';

const KakvoEGpon = styled.div``;

const Hero = styled.div`
	padding: 140px 0 0;
	overflow: hidden;
	margin-bottom: -20%;

	@media screen and (max-width: 1360px) {
		margin-bottom: 0;
	}

	.marked {
		color: #158fce;
	}

	hr {
		margin: 55px 0 25px;
	}

	.heroBg {
		margin: -10% auto 0;
		max-width: 120%;

		@media screen and (max-width: 991px) {
			margin: -10% 0 -40% -10%;
		}
	}

	.col--heroContent {
		padding-bottom: 25% !important;
		display: flex;
		align-items: center;
	}

	@media screen and (max-width: 991px) {
		.col--heroContent {
			text-align: center;
			order: 2;
			padding-bottom: 60px;

			div {
				width: 100%;
			}

			h1 {
				font-size: 29px;
			}

			hr {
				background-position: 50% 50%;
			}

			p {
				margin-bottom: 60px;
			}
		}
		.col--heroImg {
			order: 1;
		}
	}
`;

const HeroPlay = styled.button`
	position: absolute;
	top: 33%;
	left: 66%;
	width: 130px;
	height: 130px;
	margin: -65px 0 0 -65px;
	padding: 0;
	border: 0;
	outline: 0;
	border-radius: 65px;
	background: #60feb2;
	box-shadow: 0 6px 10px rgba(96, 254, 178, 0.6);

	@media screen and (max-width: 991px) {
		top: 50%;
		left: 50%;
		width: 86px;
		height: 86px;
		margin: -43px 0 0 -43px;
		border-radius: 43px;
	}

	&:after {
		display: block;
		position: absolute;
		content: '';
		top: 50%;
		left: 50%;
		width: 30px;
		height: 46px;
		margin: -23px 0 0 -10px;
		background: url(${heroPlay}) no-repeat 50% 50% / contain;

		@media screen and (max-width: 991px) {
			width: 20px;
			height: 30px;
			margin: -15px 0 0 -5px;
		}
	}
`;

const FAQs = styled.div`
	position: relative;

	.faq__img--top {
		width: 100%;
		margin-bottom: -20vw;
	}

	.faq__img--bottom {
		position: absolute;
		top: 100%;
		left: 0;
		width: 100%;
		z-index: 1;
		margin-top: -2px;
	}

	#collapsable-faq {
		background: #151f41;
		color: #fff;
		padding: 0 0 40px;
		margin: 0;

		h2 {
			color: #fff;
		}

		// > .row {
		//   transform: translateY(-25%);

		//   @media screen and (max-width: 991px) {
		//     transform: none;
		//   }
		// }
	}
`;

const Cards = styled.div`
	background: url(${cardsBg}) no-repeat 100% 0 / contain;

	h2 {
		padding-top: 170px;
	}

	hr {
		margin: 40px 0;
	}

	.container > p {
		margin-bottom: 60px;
	}
`;

const Card = styled.div`
	background: #fff;
	height: 420px;
	box-shadow: 0 0 16px rgba(128, 133, 153, 0.16);
	border-radius: 8px;
	overflow: hidden;
	position: relative;
	margin-bottom: 30px;

	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		width: 56px;
		height: 44px;
		background: #f4f5fa url(${arrow}) no-repeat 50% 50%;
		border-radius: 19px 19px 0 0;
	}

	&:hover {
		&::after {
			background-color: #60feb2;
			border-radius: 0 0 19px 19px;
			transform: translateX(-50%) rotate(180deg);
		}

		p {
			display: block;
		}
	}

	.card__imageHolder {
		height: 152px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin: 66px 0 33px;
		width: 100%;
	}

	h3 {
		text-align: center;
		padding: 0 30px;
	}

	p {
		position: absolute;
		display: none;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		padding: 40px 40px 80px;
		overflow: auto;
		font-size: 15px;
		background: #151f41;
		color: #fff;
	}
`;

const HowItWorks = styled.div`
	background: url(${hiwBg}) no-repeat 0 50%/150% auto;
	padding: 25vw 0 55vw;
	margin: -8vw 0;
	overflow: hidden;

	@media screen and (max-width: 1360px) {
		padding: 0;
		margin: 0;
		background: none;

		.hiw__content {
			background: #161f41;
			margin: -2px 0;
		}

		.hiw__img--top {
			display: block !important;
			transform: rotate(180deg) scaleX(-1);
			width: 100%;
		}

		.hiw__img--bottomImg {
			background: #171f41;
			display: block !important;
			width: 100%;
		}

		.hiw__img--bottom {
			display: block !important;
			transform: rotate(180deg) translateY(100%);
			width: 100%;
		}
	}

	h2,
	p {
		color: #fff;
	}
`;

const TechnologyTable = styled.div`
	table {
		width: 100%;
		font-family: Comfortaa;
		margin-top: 60px;
	}

	thead {
		font-size: 16px;
	}

	tbody {
		font-size: 22px;

		@media screen and (max-width: 991px) {
			font-size: 16px;
		}
	}

	th {
		background: #151f41;
		color: #fff;
		height: 56px;

		&:first-child {
			border-radius: 8px 0 0 0;
		}

		&.graph {
			border-radius: 0 8px 0 0;

			@media screen and (max-width: 991px) {
				display: none;
			}
		}

		&.security {
			@media screen and (max-width: 991px) {
				border-radius: 0 8px 0 0;
			}
		}
	}

	tr {
		@media screen and (max-width: 991px) {
			display: flex;
			flex-wrap: wrap;
		}
	}

	th,
	td {
		@media screen and (max-width: 991px) {
			flex: 1;
		}

		&:first-child {
			padding-left: 50px;

			@media screen and (max-width: 991px) {
				padding-left: 10px;
			}
		}

		&:last-child {
			@media screen and (max-width: 991px) {
				padding-right: 0;
				flex: 0 0 100%;
			}
		}
	}

	td {
		color: #151f41;
		border-bottom: 1px solid #d0d2dc;

		@media screen and (max-width: 991px) {
			border-bottom: 0;
			padding-top: 10px;
		}
	}

	.speed {
		font-family: Montserrat;

		@media screen and (max-width: 991px) {
			text-align: center;
		}
	}

	.unit {
		color: #808599;
		font-size: 18px;
	}

	.security {
		text-align: center;

		@media screen and (max-width: 991px) {
			text-align: right;
			padding-right: 10px;
		}
	}

	.graph {
		text-align: center;
		padding-right: 50px;

		@media screen and (max-width: 991px) {
			border-bottom: 1px solid #d0d2dc;
			padding-right: 0;
		}
	}
`;

const GponComareTable = styled.table`
	@media screen and (max-width: 991px) {
		display: table !important;
	}
`;

const reasons = [
	{
		title: 'За многократно по-висока скорост и стабилност на връзката',
		text:
			'Най-новото поколение технология GPON позволява предаване на данни със скорост до 2500 Mbit/s в определени условия. Това многократно надхвърля всички предлагани досега интернет скорости и от LAN, и от ADSL, и особено от DOCSIS мрежите. Специално изтеглянето на данни от интернет чрез GPON е около 100 пъти по-бързо от максимално възможното в ADSL.'
	},
	{
		title: 'За неповлияни от метеорологичните условия и енергото ТВ и интернет',
		text:
			'За мълниите са лесна мишена висящите на открито по блокове, стълбове и дървета кабели на LAN, ADSL интернет доставчици и сателитни чинии. С GPON оптичен интернет обаче връзката ти е непоклатима, а ТВ сигналът е безупречен. Влакнестият кабел без ток и липсата на активни устройства (суичове) осигуряват това безценно предимство на оптичната технология от най-ново поколение. Така че при обилен снеговалеж снежинките вече няма да се пренасят от вън на екрана на телевизора ти.'
	},
	{
		title: 'За независеща от активността на трафика интернет скорост',
		text:
			'Постоянна бързина и неповлияване от електромагнитни смущения – това са характеристиките на GPON интернет, доставен директно до теб с оптичен кабел. Оптичните линии се превръщат в пълен хегемон, измествайки „медните“ комуникационни линии като ADSL. При телефонните пък всеки теч или неизправност води до шум, оттам до срив на твоите телевизия и интернет. Освен това крайната скорост намалява с всяко отдалечаване от централата. Е, вече не.'
	},
	{
		title:
			'За доставка на интернет, телевизия, телефон и още куп услуги само по един кабел',
		text:
			'GPON мрежа единствена докарва до твоята сграда само с един кабел оптичен интернет, HD интерактивна цифрова телевизия, телефон и още услуги. В това отношение е безспорен фаворит пред LAN, ADSL и DOCSIS технологиите.'
	},
	{
		title: 'За много по-голяма сигурност на данните чрез криптиран сигнал',
		text:
			'При LAN-мрежите всички треперехме за своята интернет сигурност и защитеност. Там споделените файлове са разпръснати из цялата мрежа и нейното администриране е тежка задача. При GPON се използва криптиране на данните, за да бъдат предпазени те от хакване. Така става много трудно нежелани субекти да прихванат ценна за теб информация.'
	}
];

const faqs = [
	{
		title: 'Да страдаш от ниска скорост и прекъсваща връзка',
		content:
			'Не можеш да изгледаш нормално и един мач или филм на онлайн стрийм? Нито да си свършиш като хората работата в офиса или вкъщи? Пропускаш така чаканата възможност да се видиш и чуеш във видеочат с близък човек? Насича ти онлайн играта и ти се бавят YouTube клипчетата и зареждането на Facebook feed-а? Е, предлаганата от нас GPON технология от най-ново поколение решава всички гореизброени проблеми. Този високоскоростен и непрекъсваем GPON <a href="/">оптичен интернет</a> ще ти осигури невиждана досега плавност и сигурност в забавлението или труда онлайн.'
	},
	{
		title: 'Да имаш проблеми с нета при бури и нестабилен ток',
		content:
			'С GPON интернет спираш да следиш трепетно прогнозата за времето. Връзката ти ще я бъде, стига само да имаш лаптоп, таблет или друго устройство с независим източник на електрозахранване. Бурите и номерата на енергото не вървят при тази ултранова технология: GPON. Тя работи с оптичен кабел без ток, сигналът е лъч светлина и няма активни устройства (суичове) между абоната и доставчика.'
	},
	{
		title: 'Да ти пада винаги скоростта при засилен трафик',
		content:
			'С GPON свързаност казваме „стоп“ на рязко спадащата скорост на твоя интернет в пиковите часове и в гъсто населени жилищни зони. Гаранция за постоянната бързина на връзката е полагането на оптичен кабел директно в дома или офиса ти - Fiber to the Home (FTTH). Това елиминира риска от възможно прикачване на линията ти от външни лица, както често става при телефонния и телевизионния кабел.'
	},
	{
		title: 'Да се оплиташ и спъваш в кабели на терасата',
		content:
			'Заложиш ли на GPON оборудване, ти се спестява това „удоволствие“. Само с един тънък кабел тя докарва до жилището или офиса ти оптичен интернет и HD интерактивна <a href="/">цифрова телевизия</a>. Да не говорим, че като бонус може да добавиш още ползотворни услуги. Сред тях са например видеонаблюдение, сигурност и противопожарна охрана. Просто несравним по удобство на ползване, а отгоре на това и по универсалност, домашен интернет и телевизия.'
	},
	{
		title: 'Да се плашиш, че данните ти са лесна плячка за хакери',
		content:
			'Вече можеш да си отдъхнеш спокойно. Свръхмодерната технология GPON използва криптиране на данните, за да бъдат предпазени те от подслушване. Ако искаш специалните служби, мултинационалните корпорации или обикновените хакери по-трудно да извличат полезна (за тях) информация за теб, това е твоето решение.'
	}
];

const Cta = ({ bgImg }) => {
	return (
		<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
			<PackageCta bgImg={bgImg} noSpacingBottom={true}>
				<h2 className="my-md-5 mb-2 text-white">
					Хей, а вече можеше да си се
					<br />
					уверил колко сме добри!
				</h2>
				<Link to={'/za-doma/'} className="btn btn-primary green mt-5 mt-md-0">
					Към пакетите
				</Link>
			</PackageCta>
		</ScrollAnimation>
	);
};

const KakvoEGponPage = ({ data }) => {
	const [showVideo, setShowVideo] = useState(false);

	return (
		<>
			<style
				dangerouslySetInnerHTML={{
					__html: `
        body {
          background-image: none;
        }

        .modal--gpon .modal-dialog {
          max-width: 872px;
          transform: translate(-50%, -50%) !important;
          top: 50%;
          left: 50%;
          margin: 0;
        }

        @media screen and (max-width: 991px) {
          .modal--gpon .modal-dialog iframe {
            height: 300px;
          }
        }
      `
				}}
			></style>
			<KakvoEGpon>
				<Layout activeTab={'/kakvo-e-gpon/'}>
					<SEO
						title={meta.kakvoEGpon.title}
						description={meta.kakvoEGpon.metaDescription}
					/>

					<Hero className="hero">
						<div className="row d-flex mx-md-5 px-lg-5">
							<div className="col-12 p-0 m-0 col-lg-6 col--heroContent">
								<div className="ml-xl-4">
									<h1 className="mt-5 mx-1">
										Непрекъсваема GPON връзка.
										<br />
										<span className="marked">
											Интернет с космическа скорост!
										</span>{' '}
									</h1>
								</div>
							</div>
							<div className="col-12 p-0 m-0 col-lg-6 col--heroImg mt-5 text-center">
								<iframe
									width="600"
									height="330"
									src="https://www.youtube.com/embed/wJnuSqXfxu4"
									frameBorder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
									allowFullScreen
									title="NetSurf - GPON"
									style={{ maxWidth: '100%' }}
								></iframe>
							</div>
						</div>
					</Hero>

					<div className='mt-5'></div>

					<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
						<FAQs>
							<img className="faq__img--top mt-5" src={faqTop} alt="" />
							<CollapsibleSection
								faqs={faqs}
								isDark={true}
								sectionTitle={
									<>
										<h2 style={{ whiteSpace: 'pre-wrap' }}>
											Какво отказваш повече
											<br /> да търпиш?
										</h2>
										<hr className="center green" />
									</>
								}
							/>
							<img className="faq__img--bottom" src={faqBottom} alt="" />
						</FAQs>
					</ScrollAnimation>

					<Cards>
						<div className="container">
							<h2 className="text-center">
								GPON е вече тук да те спаси
								<br /> от всяка беда
							</h2>
							<hr className="blue center" />
							<p className="text-center">
								“Кефът цена няма”– БГ мантра, която важи с пълна сила и в
								света на технологиите. Затова и GPON е вече тук да те
								облекчи от всяка грижа по отношение на интернет и
								телевизия. Какво по-голямо удоволствие от това да гледаш
								4K клипчета в YouTube, а хлапето ти да цъка Fortnite без
								лаг! Е, на ADSL например как ще стане това? Ако пък навън
								е страшна буря, оставаш без сигнал за няколко дни,
								гарантирано.
							</p>

							<div className="row">
								<div className="col-12 col-md-4">
									<Card>
										<div className="card__imageHolder">
											<img
												src={reason1}
												alt="скорост"
											/>
										</div>
										<h3>{reasons[0].title}</h3>
										<p>{reasons[0].text}</p>
									</Card>
								</div>

								<div className="col-12 col-md-4">
									<Card>
										<div className="card__imageHolder">
											<img
												src={reason2}
												alt="скорост"
											/>
										</div>
										<h3>{reasons[1].title}</h3>
										<p>{reasons[1].text}</p>
									</Card>
								</div>

								<div className="col-12 col-md-4">
									<Card>
										<div className="card__imageHolder">
											<img
												src={reason3}
												alt="скорост"
											/>
										</div>
										<h3>{reasons[2].title}</h3>
										<p>{reasons[2].text}</p>
									</Card>
								</div>
							</div>

							<div className="row">
								<div className="col-12 col-md-4 offset-md-2">
									<Card>
										<div className="card__imageHolder">
											<img
												src={reason4}
												alt="скорост и стабилност"
												style={{ alignSelf: 'flex-end' }}
											/>
										</div>
										<h3>{reasons[3].title}</h3>
										<p>{reasons[3].text}</p>
									</Card>
								</div>

								<div className="col-12 col-md-4">
									<Card>
										<div className="card__imageHolder">
											<img
												src={reason5}
												alt="скорост и стабилност"
											/>
										</div>
										<h3>{reasons[4].title}</h3>
										<p>{reasons[4].text}</p>
									</Card>
								</div>
							</div>
						</div>
					</Cards>

					<TechnologyTable>
						<div className="container">
							<table>
								<thead>
									<tr>
										<th>Технология</th>
										<th className="speed">Макс. скорост</th>
										<th className="security">Сигурност</th>
										<th className="graph">Непрекъсваемост</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>GPON</td>
										<td className="speed">
											2500 <span className="unit">Mbit/s</span>
										</td>
										<td className="security">
											<img src={securityHigh} alt="висока" />
										</td>
										<td className="graph">
											<img src={signalGpon} alt="GPON signal" />
										</td>
									</tr>

									<tr>
										<td>LAN</td>
										<td className="speed">
											500-600 <span className="unit">Mbit/s</span>
										</td>
										<td className="security">
											<img src={securityMedium} alt="средна" />
										</td>
										<td className="graph">
											<img src={signalLan} alt="LAN signal" />
										</td>
									</tr>

									<tr>
										<td>DOCSIS 2.0</td>
										<td className="speed">
											40 <span className="unit">Mbit/s</span>
										</td>
										<td className="security">
											<img src={securityHigh} alt="висока" />
										</td>
										<td className="graph">
											<img
												src={signalDocsis}
												alt="DOCSIS 2.0 signal"
											/>
										</td>
									</tr>

									<tr>
										<td>ADSL</td>
										<td className="speed">
											24 <span className="unit">Mbit/s</span>
										</td>
										<td className="security">
											<img src={securityLow} alt="ниска" />
										</td>
										<td className="graph">
											<img src={signalAdsl} alt="ADSL signal" />
										</td>
									</tr>

									<tr>
										<td>Wireless</td>
										<td className="speed">
											50-80 <span className="unit">Mbit/s</span>
										</td>
										<td className="security">
											<img src={securityLow} alt="ниска" />
										</td>
										<td className="graph">
											<img
												src={signalWireless}
												alt="wireless signal"
											/>
										</td>
									</tr>

									<tr>
										<td>GPRS (3G/4G)</td>
										<td className="speed">
											7.2/300 <span className="unit">Mbit/s</span>
										</td>
										<td className="security">
											<img src={securityMedium} alt="средна" />
										</td>
										<td className="graph">
											<img src={signalGprs} alt="GPRS signal" />
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</TechnologyTable>
					{/* <GponCompareTable>
            <thead>
              <tr>
                <td>GPON</td>
                <td>
                  <button>&lt;</button>
                  {technologies[tIndex].name}
                  <button>&gt;</button>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </GponCompareTable> */}

					<Cta bgImg={data.ctaBgImage.childImageSharp.fluid.src}></Cta>

					<HowItWorks>
						<img className="hiw__img--top d-none" src={faqBottom} alt="" />
						<div className="hiw__content">
							<div className="container">
								<div className="row">
									<div className="col-12 col-md-10 offset-md-1 text-center">
										<h2>Как работи GPON</h2>

										<hr className="green center" />

										<p>
											Докато гореспоменатите технологии работят или
											по мед, или по въздух, технологията GPON
											работи изцяло със светлина. Сигналът (лъчът
											светлина) тръгва от нашия офис и пристига до
											твоето ONT (оптичен GPON рутер, а не
											архаичното GPON модем, както някои още го
											наричат). Този начин на свързване се нарича
											Fiber to the Home (FTTH). На този лъч не са му
											необходими активни устройства, тип усилватели
											(switch-ове), нито плеяда от ел. захранвания
											по трасето. При LAN-а или DOCSIS-а и при
											най-малкото премигване на тока много често
											активните устройства забиват. И не само.
										</p>
										<p>
											След като приеме GPON сигнала, ONT-то го
											разкодира в ТВ и LAN/Wi-Fi сигнал.
											Телевизионният сигнал се подава към твоите ТВ
											приемници, докато LAN/Wi-Fi сигналът се
											предава до твоите интернет устройства.
										</p>
										<p>
											По този начин се гради ефикасно работеща GPON
											мрежа. И NetSurf ти предлага цялото нужно GPON
											оборудване, за да се радваш на всички нейни
											преимущества пред конкурентните технологии.
										</p>
									</div>
								</div>
							</div>
						</div>
						<img className="hiw__img--bottomImg d-none" src={hiwImg} alt="" />
						<img
							className="hiw__img--bottom d-none"
							src={hiwBottomMobile}
							alt=""
						/>
					</HowItWorks>

					<div className="offer">
						<div className="container">
							<div className="row">
								<div className="col-12 col-md-10 offset-md-1">
									<h2 className="text-center">
										Какво ти предлагаме с GPON
									</h2>

									<hr className="center blue my-3" />

									<p className="text-center">
										Технология от бъдещето за твоето настояще – този
										свръхнов GPON шампион ти предлагаме, за да имаш
										светкавичен и непрекъсваем оптичен интернет за
										дома или офиса. За забавление или по работа, вече
										ще можеш да правиш онлайн всичко, без да се
										тревожиш за саботажи от времето или енергото. И то
										при денонощна връзка с нас.
									</p>

									<h3 className="text-center my-5">
										Ето какво получаваш с GPON пакетите на NetSurf:
									</h3>

									<ul className="green-ticks">
										<li>
											<p>
												<strong>
													Непрекъсваем интернет по всяко време и
													във всякакво време
												</strong>
												, за да не пропускаш нито една home office
												оперативка, а хлапето да цъка League of
												Legends без лаг
											</p>
										</li>
										<li>
											<p>
												<strong>Свръхзвукови скорости</strong> без
												конкуренция{' '}
												<strong>
													до реално достижими 300 Mbit/s
												</strong>
												, за да се зареждат Facebook feed-ът и
												видеоклипове за части от секундата
											</p>
										</li>
										<li>
											<p>
												<strong>
													Безплатен 5GHz Wi-Fi GPON рутер (ONT)
												</strong>{' '}
												за още повече бързина, стабилност и обхват
												на връзката*
												<br />
												<small>
													*получава го за срока на договора си
													всеки клиент на GPON свързаност,
													избрал пакет със скорост на интернет
													175 Mbit/s или по-висока
												</small>
											</p>
										</li>
										<li>
											<p>
												<strong>Безопасност на връзката</strong>,
												за да се чувстваш по-сигурен с твоето
												онлайн банкиране и онлайн плащания
											</p>
										</li>
										<li>
											<p>
												Качествени <strong>HD канали</strong> и{' '}
												<strong>5 дни назад Timeshift</strong>, за
												да не изпускаш нищо интересно по
												телевизията
											</p>
										</li>
										<li>
											<p>
												Възможност за{' '}
												<strong>
													избор между едногодишни и двугодишни
													договори
												</strong>
												, и то без скрити клаузи
											</p>
										</li>
										<li>
											<p>
												Възможност за{' '}
												<strong>
													избор между три готови пакета
												</strong>{' '}
												и <strong>съставяне на твой</strong>{' '}
												според собствените ти нужди и изисквания
											</p>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>

					<Cta bgImg={data.ctaBgImage.childImageSharp.fluid.src}></Cta>
				</Layout>
			</KakvoEGpon>
		</>
	);
};

export const query = graphql`
	query KakvoEGponPageQuery {
		heroImage: file(relativePath: { eq: "gpon/hero.jpg" }) {
			childImageSharp {
				fluid(maxHeight: 1030) {
					...GatsbyImageSharpFluid_withWebp_noBase64
				}
			}
		}
		ctaBgImage: file(relativePath: { eq: "home/cta-bg.png" }) {
			childImageSharp {
				fluid(maxHeight: 1500) {
					...GatsbyImageSharpFluid_withWebp_noBase64
				}
			}
		}
	}
`;

export default KakvoEGponPage;

