import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  background-image: url(${props => props.bgImg});
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 8px;
  box-shadow: 0px 8.71336px 58.089px rgba(0, 0, 0, 0.15);
  min-height: 24rem;
  max-height: 30rem;

  @media screen and (max-width: 768px) {
    min-height: unset;
  }
`;

const PackageCta = ({ bgImg, children, noSpacingBottom }) => {
  return (
    <>
      <section id='package-cta' className={'center ' + (!noSpacingBottom && 'spacing-bottom')}>
          <div className='row my-5 justify-content-center align-items-center w-100'>
            <Wrapper className='container elevated text-center mx-2 my-5 py-5' bgImg={bgImg}>
              <div className='row'>
                <div className='col-12 px-md-5'>
                  {children}
                </div>
              </div>
            </Wrapper>
          </div>
      </section>
    </>
  );
}

export default PackageCta;